import { useLayoutEffect, useRef } from "react";
import { SSEProvider, useSSE } from "react-hooks-sse";

function StreamingOutput() {
  const pre = useRef<HTMLPreElement | null>(null);
  const hasAutoScroll = useRef(true);

  // Concatenate the data from the "output" events into a single string
  const output = useSSE<string, string>("output", "", {
    parser: (data) => {
      if (typeof data === "string") {
        return data;
      } else {
        return "";
      }
    },
    stateReducer: (state, action) => {
      return state + action.data;
    },
  });

  // Listen for the "done" event and set to true when streaming completes.
  const isDone = useSSE<boolean>("done", false, {
    stateReducer: () => true,
  });

  useLayoutEffect(function () {
    if (!pre.current || !hasAutoScroll.current) return;
    pre.current.scrollTop = pre.current.offsetHeight;
  });

  function handleMouseEnter() {
    // cancel auto scrolling if user interacts with the output
    hasAutoScroll.current = false;
  }
  function handleMouseLeave() {
    // cancel auto scrolling if user interacts with the output
    hasAutoScroll.current = true;
  }

  return (
    <pre
      ref={pre}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="bg-white text-black h-full text-sm overflow-auto p-4 whitespace-pre-wrap"
    >
      {output}
      {isDone ? null : <span className="blink">█</span>}
    </pre>
  );
}

export function PredictionStreamingOutput({
  streamUrl,
}: {
  streamUrl: string;
}) {
  return (
    <SSEProvider endpoint={streamUrl}>
      <StreamingOutput />
    </SSEProvider>
  );
}
